import React, { useEffect, useState } from "react"
import $ from 'jquery'
import Row from 'react-bootstrap/Row';
import global from './global.js';
import { useLocation } from "react-router-dom";


const DropdownMenu = ({ selectedIdPassed, onChange }) => {
  window.currentPage = window.location.pathname;

  useEffect(() => {
    if (window.currentPage === '/biodiversitet') {
      $('#kirke').addClass('d-flex');
      $('#kirke').removeClass('d-none');
      $('#columns').removeClass('col-lg-4')
      $('#columns1').removeClass('col-lg-4')
      $('#columns2').removeClass('col-lg-4')
      $('#columns3').removeClass('col-lg-4')

      $('#columns').addClass('col-lg-3')
      $('#columns1').addClass('col-lg-3 pdr')
      $('#columns2').addClass('col-lg-3 pdl')
      $('#columns3').addClass('col-lg-3')

      $('#drop3').addClass('justify-content-lg-center justify-content-md-end')
      $('#drop3').removeClass('justify-content-lg-start')
    } 

  }, []); 

  const [organization, setOrganization] = useState([])
  const [deanery, setDeanery] = useState([])
  const [congregation, setCongregation] = useState([])
  const [cemetry, setCemetryData] = useState([])
  const [HasCemetry, setHasCemetryData] = useState([])

  const fetchOrganizationData = () => {
    fetch(`${global.url}/Organization/GetDioceses`)
      .then (response => {
          return response.json()
      })
      .then(data => {
          setOrganization(data)
      })
  }

  const fetchDeaneryData = async (selectedId) => {

      if (!selectedId) return;

      try {

        const response = await fetch(`${global.url}/Organization/GetDeaneriesByDioceseId/${selectedId}`);

        const data = await response.json();

        setDeanery(data);

      } catch (error) {

        console.error('Error fetching data:', error);

      }
  }

  const fetchCongregationsData = async (selectedId) => {

    if (!selectedId) return;

    try {

      const response = await fetch(`${global.url}/Organization/GetCongregationsByDeaneryId/${selectedId}`);

      const data = await response.json();

      setCongregation(data);

    } catch (error) {

      console.error('Error fetching data:', error);

    }
  }

  const fetchCemetryData = async (selectedId) => {

    if (!selectedId) return;

    try {

      const response = await fetch(`${global.url}/Organization/GetCemeteriesByCongregationId/${selectedId}`);

      const data = await response.json();

      setCemetryData(data);

    } catch (error) {

      console.error('Error fetching data:', error);

    }
  }

  const fetchAllCemetryData = () => {
    fetch(`${global.url}/Organization/GetAllCemeteries`)
      .then (response => {
          return response.json()
      })
      .then(data => {
        setHasCemetryData(data)
      })
  }

  const StiftChange = (event) => {
    window.selectedIdStift = event.target.value;
    
    fetchAllCemetryData();
    onChange(window.selectedIdStift);
    if (window.selectedIdStift !== null || window.selectedIdStift !== 'null') {
      fetchDeaneryData(window.selectedIdStift);
    }

    window.selectedIdProvsti = null;
    window.selectedIdMr = null;

    localStorage.setItem('StiftId', window.selectedIdStift);
    localStorage.setItem('ProvstiId', window.selectedIdProvsti);
    localStorage.setItem('MrId', window.selectedIdMr);

  };


  const ProvstiChange = (event) => {
    window.selectedIdProvsti = event.target.value;
    if(parseInt(window.selectedIdProvsti) == -1){
      onChange(window.selectedIdStift)
    }
    else{
      onChange(window.selectedIdProvsti)
    }
    if (window.selectedIdProvsti !== null || window.selectedIdProvsti !== 'null') {
      fetchCongregationsData(window.selectedIdProvsti);
    }
    window.selectedIdMr = null;
    window.selectedIdKr = null;

    fetchCemetryData(window.selectedIdMr);
    localStorage.setItem('ProvstiId', window.selectedIdProvsti);
    localStorage.setItem('MrId', window.selectedIdMr);
    localStorage.setItem('KrId', window.selectedIdKr);


  };

  const MrChange = (event) => {
    window.selectedIdMr = event.target.value;
    if (window.selectedIdMr !== null || window.selectedIdMr !== 'null') {
      if(parseInt(window.selectedIdMr) == -2){
        onChange(window.selectedIdProvsti)
      }
      else{
        onChange(window.selectedIdMr)
       
      }
      
    }
    if (window.selectedIdMr !== null || window.selectedIdMr !== 'null') {
      fetchCemetryData(window.selectedIdMr);
    }
    window.selectedIdKr = null;
    localStorage.setItem('MrId', window.selectedIdMr);
    localStorage.setItem('KrId', window.selectedIdKr);

  }

  const KrChange = (event) => {
    window.selectedIdKr = event.target.value;
    localStorage.setItem('KrId', window.selectedIdKr);
    if (window.currentPage == '/biodiversitet') {
      onChange(window.selectedIdKr);
    } 
  }
  const location = useLocation();

  useEffect(() => {
    fetchOrganizationData()

    fetchAllCemetryData();

    const retrievedNumber = localStorage.getItem('StiftId');
    window.selectedIdStift = (retrievedNumber);
    fetchDeaneryData(window.selectedIdStift);
    onChange(window.selectedIdStift);

    const retrievedNumber2 = localStorage.getItem('ProvstiId');
    if (retrievedNumber2 === 'null'){
      window.selectedIdProvsti = null;
    }
    else{
      if(parseInt(retrievedNumber2) != -1){
        window.selectedIdProvsti = parseInt(retrievedNumber2);
        onChange(window.selectedIdProvsti);
        fetchCongregationsData(window.selectedIdProvsti)
      }
    }
  
    const retrievedNumber3 = localStorage.getItem('MrId');
  
    if (retrievedNumber3 === 'null'){
      window.selectedIdMr = null;
    }
    else{

      if(parseInt(retrievedNumber3) != -2){
        window.selectedIdMr = parseInt(retrievedNumber3);
        onChange(window.selectedIdMr);
        fetchCemetryData(window.selectedIdMr);  
      }
    }
  
    const retrieveNumber4 = localStorage.getItem('KrId');
  
    if (retrieveNumber4 === 'null'){
      window.selectedIdKr = null;
    }
    else{
      window.selectedIdKr = parseInt(retrieveNumber4);
      if (window.currentPage == '/biodiversitet') {
        onChange(window.selectedIdKr);
      } 
    }

  }, [])
  window.MrHasCemetry = HasCemetry.map(mr => mr.id);
  window.PrHasCemetry = HasCemetry.map(mr => mr.deaneryId);

  return (
    <div className="dropdown-section mt-4">
      <Row className="justify-content-center align-items-center">
        <div id="columns" className="col-lg-4 col-md-6 col-12">
          <div className="d-flex justify-content-center justify-content-md-end mt-lg-0 mt-3">
            <select className="form-select custom-select" id="stiftdrop" aria-label="dropdown1" onChange={StiftChange} value={window.selectedIdStift == null ? '0' : window.selectedIdStift}>
              <option value='0' key='0'>Folkekirken</option>
              {organization.length > 0 && (
                <>
                  {organization.map(stift =>(
                    <option value={stift.id} key={stift.id}>{stift.name}</option>
                  ))}
                </>
              )}
            </select>
          </div>
        </div>
        <div id="columns1" className="col-lg-4 col-md-6 col-12">          
          <div id="drop2" className="d-flex justify-content-center justify-content-md-start justify-content-lg-center mt-lg-0 mt-3">
            <select className="form-select custom-select" id="provstidrop" aria-label="dropdown2" onChange={ProvstiChange} value={window.selectedIdProvsti == null ? '0' : window.selectedIdProvsti }>
            <option value='-1' key='-1'>Vælg Provsti</option>

              {window.currentPage === '/biodiversitet'
                ?
                deanery.length > 0 && (
                  <>
                    {deanery.map((provsti) =>
                    window.PrHasCemetry.includes(provsti.id) ? (
                      <option value={provsti.id} key={provsti.id}>
                        {provsti.name}
                      </option>
                      ) : (
                      <option disabled value={provsti.id} key={provsti.id}>{provsti.name}</option>
                      )
                    )}
                  </>
                ) 
                :
                deanery.length > 0 && (
                  <>
                    {deanery.map(provsti =>(
                      <option value={provsti.id} key={provsti.id}>{provsti.name}</option>
                    ))}
                  </>
                ) 
              }
            </select>
          </div>
        </div>
        
        <div id="columns2" className="col-lg-4 col-md-6 col-12">          
          <div id="drop3" className="d-flex justify-content-center justify-content-md-end justify-content-lg-start mt-lg-0 mt-3">
            <select className="form-select custom-select" id="mrdrop" aria-label="dropdown3" onChange={MrChange} value = {window.selectedIdMr == null ? '0' : window.selectedIdMr}>
              <option value='-2' key='-2'>Vælg Menighedsråd</option>
              {window.currentPage === '/biodiversitet'
                ? window.selectedIdProvsti == null
                  ? ''
                  : congregation.map((mr) =>
                      window.MrHasCemetry.includes(mr.id) ? (
                        <option value={mr.id} key={mr.id}>
                          {mr.name}
                        </option>
                      ) : (
                        <option disabled value={mr.id} key={mr.id}>{mr.name}</option>
                      )
                    )
                : window.selectedIdProvsti == null
                ? ''
                : congregation.map((mr) => (
                    <option value={mr.id} key={mr.id}>{mr.name}</option>
                  )
                )
              }
            </select>
          </div>
        </div>
        <div id="columns3" className="col-lg-4 col-md-6 col-12">          
          <div id="kirke" className="d-none justify-content-center justify-content-md-start mt-lg-0 mt-3">
            <select className="form-select custom-select" aria-label="dropdown3" onChange={KrChange} value = {window.selectedIdKr == null ? '0' : window.selectedIdKr}>
              <option value='-3' key='-3'>Vælg Kirkegård</option>
              {window.selectedIdMr == null ? '' :
                cemetry.length > 0 &&(
                  <>
                    {cemetry.map(kirkeg => (
                      <option value={kirkeg.id} key={kirkeg.id}>{kirkeg.name}</option>
                    ))}
                  </>
                )
              }

            </select>
          </div>
        </div>
      </Row>
    </div>
  );
}

export default DropdownMenu;
