import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InfoBox from './InfoBox.js';
import global from './global.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieCharts = ({ selectedId }) => {

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  const [shareEnergy, setshareEnergy] = useState([])

  useEffect(() => {

    const fetchshareEnergy = async () => {

      try {
        const response = await fetch(`${global.url}/EnergyConsumption/GetShareOfEnergyTotal`);

        const data = await response.json();

        setshareEnergy(data);
      } 
      catch (error) {
        console.error('Error fetching data:', error);
      } 
    };
    
    const fetchshareEnergyStift = async () => {

      try {
        const response = await fetch(`${global.url}/EnergyConsumption/GetShareOfEnergyByDioceseId/${selectedId}`);

        const data = await response.json();

        setshareEnergy(data);
      } 
      catch (error) {
        console.error('Error fetching data:', error);
      } 
    };

    const fetchshareEnergyProvsti = async () => {

      try {
        const response = await fetch(`${global.url}/EnergyConsumption/GetShareOfEnergyByDeaneryId/${selectedId}`);

        const data = await response.json();

        setshareEnergy(data);
      } 
      catch (error) {
        console.error('Error fetching data:', error);
      } 
    };

    const fetchshareEnergyMr = async () => {

      try {
        const response = await fetch(`${global.url}/EnergyConsumption/GetShareOfEnergyByCongregationId/${selectedId}`);

        const data = await response.json();

        setshareEnergy(data);
      } 
      catch (error) {
        console.error('Error fetching data:', error);
      } 
    };

    if (!isNaN(selectedId) && selectedId > 0) {
      if (selectedId>4300 && selectedId <= 4310){
        fetchshareEnergyStift();
      }
      else if (selectedId > 4310 && selectedId < 4522) {
        fetchshareEnergyProvsti();
      }
      else if (selectedId > 4521) {
        fetchshareEnergyMr();
      }

    }
    else{
      if (
        (localStorage.getItem('StiftId') == '0' && localStorage.getItem('ProvstiId') == 'null' && localStorage.getItem('MrId') == 'null')|| 
        (localStorage.getItem('StiftId') == null && localStorage.getItem('ProvstiId') == null && localStorage.getItem('MrId') == null)
      ){
      fetchshareEnergy();
      }
    }
  }, [selectedId]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])


  const filter_year = (energyear, arr) => arr.filter(table => table.year === energyear );

  const filter_type = (energtype, arr) => arr.filter(table => table.consumptionType === energtype );

  const filter_nottype = (energtype, arr) => arr.filter(table => table.consumptionType !== energtype );

  const shareEnergy2019 = filter_year(2019, shareEnergy)

  const shareEnergy2019Nottotal = filter_nottype('Total', shareEnergy2019)


  const sum19 = (shareEnergy2019Nottotal.reduce((a,v) =>  a = a + v.emissionInTonne , 0 ))

  const total_2019 = filter_type('Total', shareEnergy2019)

  const percent_gren19 = Math.round((sum19/(total_2019.map(table => (table.emissionInTonne)))[0]) * 100);


  const shareEnergy2022 = filter_year(2022, shareEnergy)

  const shareEnergy2022Nottotal = filter_nottype('Total', shareEnergy2022)

  const sum22 = (shareEnergy2022Nottotal.reduce((a,v) =>  a = a + v.emissionInTonne , 0 ))

  const total_2022 = filter_type('Total', shareEnergy2022)

  const percent_gren22 =  Math.round((sum22/(total_2022.map(table => (table.emissionInTonne)))[0]) * 100);
  
    const dataChart1 = {
      labels: ['Fossilt brændstof','Grøn energi'],
      datasets: [
        {
          data: [100 - percent_gren19, percent_gren19],
          backgroundColor: ['rgb(182, 182, 182)', 'rgb(87, 156, 66)'],
          borderWidth: 0,
          doughnutSize:0.1

        },

      ],
    };
    const dataChart2 = {
      labels: ['Fossilt brændstof','Grøn energi'],
      datasets: [
        {
          data: [100 - percent_gren22, percent_gren22],
          backgroundColor: ['rgb(182, 182, 182)', 'rgb(87, 156, 66)'],
          borderWidth: 0,
        },
      ],
    };
    
    const optionsChart1 = {
      cutout: 80,
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: true,
        },
        title: {
          display: true,
          position: 'bottom',
          text: '2019',
          fontweight: 'normal'
        },
        tooltip: {
          callbacks: {
            label: function(context) {
                var label = context.dataset.label || '';
                if (context.parsed.y !== null) {
                    label += ' ' +context.parsed + '%';
                }
                return label;
            }
          }
        }    
      }
    }

    const optionsChart2 = {
      cutout: 80,
      maintainAspectRatio: true,
      responsive: true,
      plugins: {
        legend: {
          display: true,
        },
        title: {
          display: true,
          position: 'bottom',
          text: '2022'
        },
        tooltip: {
          callbacks: {
            label: function(context) {
                var label = context.dataset.label || '';
                if (context.parsed.y !== null) {
                    label += ' ' +context.parsed + '%';
                }
                return label;
            }
          }
        }  
      }
    }
    const selectedText = 'Hvis den valgte enhed har deltaget i Klimakortlægningen 2023\ner andelen af grøn energi baseret på indtastninger.\nEllers er andelen af grøn energi et gennemsnit for folkekirken.';
    const selectedTextsm = 'Hvis den valgte enhed har deltaget i Klimakortlægningen 2023 er andelen af grøn energi baseret på indtastninger. Ellers er andelen af grøn energi et gennemsnit for folkekirken.';

    const id = 1;

    return (
        <div className="graph-section-with-info pie-width right-section">
          <Row>
            <Col xs={8} className="mt-3">
              <p className="mt-1 mb-4">Energiforbruget kan ofte dækkes med grøn energi, der ikke udleder CO₂.
              Figuren viser, hvor meget af enhedens energiforbrug, der dækkes af grøn
              energi.</p>
            </Col>
            <Col xs={4} className="prInfo">
              <InfoBox selectedText={isSmallScreen ? selectedTextsm : selectedText} id={id}/>
            </Col>
          </Row>
          <h4 className="title-spacing mb-2">Andel af grøn energi</h4>
          <div className="mt3 center justify-content-center  flex-set  mb3">
            <div className="d-flex justify-content-center pd-pie-r" >
                <Doughnut data={dataChart1} options={optionsChart1}/>
            </div>
            <div className="d-flex justify-content-center pd-pie-l ">
                <Doughnut data={dataChart2} options={optionsChart2}/>
            </div>
          </div>
      </div>
    );
}

export default PieCharts;
