import React from "react";

const NotFound = () => {

  return (
    <div className="container-fluid px-sm-3">
        <div className="row">
            <h1 className="title">404</h1>
            <p className="vejl-text">Siden du leder efter blev ikke fundet.</p>
        </div>
    </div>
  );
};
export default NotFound;
