import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import global from './global.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



const EnergiforbrugBar = ({ selectedId }) =>{
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
    const [energiforbrug, setEnergiforbrugData] = useState([])

    useEffect(() => {

      const fetchEnergiforbrugDataMr = async () => {
  
        try {
          const response = await fetch(`${global.url}/EnergyConsumption/GetEnergyConsumpionPerAllEnergyTypesByCongregationId/${selectedId}`);
  
          const data = await response.json();
  
          setEnergiforbrugData(data);
        } 
        catch (error) {
          console.error('Error fetching data:', error);
        } 
      };
      if (selectedId > 4521){
        fetchEnergiforbrugDataMr();
      }
      

    }, [selectedId]);
  
    const consumptionTypes = [...new Set(energiforbrug.map(item => item.consumptionType))];
    const sortedTypes = [...consumptionTypes];
    const years = [...new Set(energiforbrug.map(item => item.year))];
    
    useEffect(() => {
      const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 768);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const TypeOrder = [
      'Opvarmning',
      'Fjernvarme',
      'Naturgas',
      'Fyringsolie',
      'Biogas',
      'Elektricitet',
      'El, eldeklaration',
      'Grøn strøm',
      'Grøn strøm, egen produktion',
      'Benzin',
      'Brændstof',
      'Diesel',
      'LPG-flaskegas',
      'BioLPG-flaskegas'
    ];

    const typeSetter = (a, b) => {
      const aIndex = TypeOrder.indexOf(a);
      const bIndex = TypeOrder.indexOf(b);
    
      return aIndex - bIndex;
    };

    sortedTypes.sort(typeSetter);

    const year1 = years[0]
    const year2 = years[1]
    const filter = (energyear, arr) => arr.filter(table => table.year === energyear );
    const filter2 = (energytype, arr) => arr.filter(table => table.consumptionType === energytype || 0);

    const valuesYear1 = sortedTypes.map(label => filter2(label,filter(year1,energiforbrug)).map(type => type.emissionInTonne.toFixed(1).toLocaleString('da-DK')  ));
    const valuesYear2 = sortedTypes.map(label => filter2(label,filter(year2,energiforbrug)).map(type => type.emissionInTonne.toFixed(1).toLocaleString('da-DK') ));

    function addZeroAndFlatten(arr){
      for (let i = 0; i < arr.length; i++){
        if(arr[i].length === 0){
          arr[i].push(0);
        }
      }
      return arr.flat()
    }
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                  var label = context.dataset.label || '';
                  if (context.parsed.y !== null) {
                    label += ' ' + ': ' + context.parsed.y.toLocaleString('da-DK') + ' MWh';
                  }
                  return label;
              }
            }
          }
        },
        scales: {
            x: {
                grid: {
                  drawOnChartArea: false,
                },
            },
            y: {
                beginAtZero: true, 
            },
        },
    };

    const labels = sortedTypes;
    const data = {

        labels,
      datasets: [
          {
            label: year1,
            data: addZeroAndFlatten(valuesYear1),
            backgroundColor: '#ed7d31',
          },        
          {
            label: year2,
            data: addZeroAndFlatten(valuesYear2),
            backgroundColor: '#fdb913',
          },

        ],
        
    };

    return(
        <div>
          {selectedId > 4521 && energiforbrug.length != 0 ?
          (
            <div>
        
            <div className="graph-section left-section">
                <p className="mb-0">Grafen viser energiforbruget i MWh for den valgte enhed. Man kan reducere CO₂-udledningen ved at mindske forbruget af særligt de fossile energityper (Fyringsolie, Naturgas, Benzin, Diesel, LPG Flaskegas).</p>
                <p className="mb-4">Elektricitet, Brændstof og Opvarmning er beregnede data. Øvrige energityper er registrerede.</p>
                <h4 className="title-spacing mb-3">Energiforbrug pr. energitype</h4>
                <p className="style3 mb-3">MWh</p>
                <Bar options={options} data={data} height={isSmallScreen ? '800px' : '200px'} width="700px"/>
            </div>
          </div>

          ) 
          : 
          (
            <div></div>
          ) }

        </div>
        
    );
};

export default EnergiforbrugBar;
