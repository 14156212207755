import React from "react"
import Klimaregnskab_opt from './Klimaregnskab_opt_.jpg';
import Biodiversitet_opt from './Biodiversitet.jpg';
import { useLocation } from 'react-router-dom';
import KlimaCircle from './Klimaregnskab_ikon-cirkel-bund.png';
import BiodiversitetCircle from './Biodiversitet_ikon-cirkel-bund.png';
import VejledningCircle from './Vejledning-ikon-cirkel_bund.png';

const Footer = () => {
    const location = useLocation();
    const currentUrl = location.pathname;
    const BackgroundStyle = {
        position: 'absolute',
        top: '75%',   
        width: '100%', 
        height: '25%',
        backgroundColor: 'rgb(87 156 66)',
        zIndex: 1,
    };
    const CircleStyle = {
        position: 'absolute',
        top: '65%', 
        zIndex: 2,
        left: '50%',
        transform: 'translateX(-50%)',  
    };
    const TextStyle = {
        position: 'absolute',
        zIndex: 2,
        color: 'white',
        top: '83%',  
        left: '50%',
        transform: 'translateX(-50%)',
    };

    if (currentUrl === '/'){
        var Main_img = Klimaregnskab_opt;
        var Circle_img = KlimaCircle;
        var Text = 'KLIMAREGNSKAB';

    }

    else if (currentUrl === '/vejledning'){
        Main_img = Klimaregnskab_opt;
        Circle_img = VejledningCircle; 
        Text = 'VEJLEDNING';
    }
    else if (currentUrl === '/biodiversitet'){
        Main_img = Biodiversitet_opt;
        Circle_img = BiodiversitetCircle;
        Text = 'BIODIVERSITET';
    }

    return(
    <div style={{ position: 'relative' }}>

        <img className='footer' id="footer" src={Main_img} alt=""/>
        <img style={CircleStyle} src={Circle_img} alt="" />
        <div className="BackgroundStyle" style={BackgroundStyle}></div> 
        <h1 style={TextStyle} className="TextStyle title">{Text}</h1>

    </div>

  );
}

export default Footer;
