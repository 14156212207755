import React, { useState, useEffect } from 'react';
import InfoBox from './InfoBox.js';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import global from './global.js';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);


const MixedChart = ({ selectedId }) => {
  const [graphData, setGraphData] = useState([]);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const fetchDataDefault = async () => {

      try {
        const response = await fetch(`${global.url}/Co2Emission/GetCo2EmissionsPerYearTotal`);

        const data = await response.json();

        setGraphData(data);

      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };
    const fetchData = async () => {

      try {
        const response = await fetch(`${global.url}/Co2Emission/GetCo2EmissionsPerYearTotalByDioceseId/${selectedId}`);

        const data = await response.json();

        setGraphData(data);
      } 
      catch (error) {
        console.error('Error fetching data:', error);
      } 
    };
  
    const fetchDataProvsti = async () => {

      try {
        const response = await fetch(`${global.url}/Co2Emission/GetCo2EmissionsPerYearTotalByDeaneryId/${selectedId}`);

        const data = await response.json();

        setGraphData(data);
      } 
      catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchDataMr = async () => {

      try {
        const response = await fetch(`${global.url}/Co2Emission/GetCo2EmissionsPerYearTotalByCongregationId/${selectedId}`);

        const data = await response.json();

        setGraphData(data);
      } 
      catch (error) {
        console.error('Error fetching data:', error);
      }

    };
  

    if (!isNaN(selectedId) && selectedId > 0) {
      if (selectedId>4300 && selectedId <= 4310){
        fetchData();
      }
      else if (selectedId > 4310 && selectedId < 4522) {
        fetchDataProvsti();
      }
      else if (selectedId > 4521) {
        fetchDataMr();
      }
    }
    else{
      if (
        (localStorage.getItem('StiftId') == '0' && localStorage.getItem('ProvstiId') == 'null' && localStorage.getItem('MrId') == 'null')|| 
        (localStorage.getItem('StiftId') == null && localStorage.getItem('ProvstiId') == null && localStorage.getItem('MrId') == null)
      ){
        fetchDataDefault();
      }
      
    }
  }, [selectedId]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])
  const filter = (arr, year) => arr.filter(emssn =>  emssn.year === year );

  const graph19 = (filter(graphData, 2019).map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0))
  const graph22 = (filter(graphData, 2022).map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0))

  const legendClickHandler = function(e, lengendItem, legend) {

  }

  var maxax = Math.round(graph19 * 1.606);
  var minax = Math.round(graph19 * 0);
  var intervalax = Math.round(((maxax-minax)/10)) ;
  var calc_70 = graph19 * 0.4818;
  const emissionsArray = [graph19.toFixed(), 0, 0, graph22.toFixed(), 0, 0, 0, 0, 0, 0, 0,  calc_70.toFixed()]

  var maxl = graph19.toFixed();
  var minl = Math.round(graph19 * 0.4818);
  var intrl = (maxl-minl)/11;

  const data = {
    labels: [
      '2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'
    ],
    datasets: [
      {
        type: 'line',
        label: '70 %-mål',
        align: 'left',
        pointRadius: [4,0,0,0,0,0,0,0,0,0,0,4],
        backgroundColor: 'rgb(128, 172, 206)',
        borderColor: 'rgb(128, 172, 206)',
        borderWidth: 4,
        data: [maxl, maxl-intrl, maxl-(intrl*2), maxl-(intrl*3), maxl-(intrl*4), maxl-(intrl*5), maxl-(intrl*6), maxl-(intrl*7), maxl-(intrl*8), maxl-(intrl*9), maxl-(intrl*10), minl],
        yAxisID: 'y',

      },
      {
        type: 'bar',
        label: 'Enhedens CO₂-udledning',
        backgroundColor: ['rgb(0, 89, 157)','rgb(0, 89, 157)','rgb(128, 172, 206)'],
        borderColor: 'white',
        borderWidth: 2,
        data: emissionsArray,
        yAxisID: 'y',

      },
    ],
  };

  const options = {
    responsive: true,
    plugins:{
      tooltip: {
        callbacks: {
          label: function(context) {
              var label = context.dataset.label || '';
              if (context.parsed.y !== null) {
                  label += ' '+': '+context.parsed.y.toLocaleString('da-DK') + ' Ton CO₂';
              }
              return label;
          }
        }
      },
      legend: {
        onClick: legendClickHandler
      }   
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },

      y: {
        max: maxax,
        min: minax,
        stepSize : intervalax,

        beginAtZero: false,
        type: 'linear',
        display: true,
        position: 'left',
        grid: {
          drawOnChartArea: true,
        },
        title: {
          display: false,
          text: 'CO₂-udledning(ton)',
        },
        ticks: {

          stepSize : intervalax,

          callback: function(value, index, values) {
            return Math.round(value).toLocaleString('da-DK'); 
          }
        },
      },

      y1: {
          stepSize: 10,
          min: -100,
          max: 0,
        beginAtZero: true,
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: false,
        },
        ticks: {
          stepSize: 10,
          min: -100,
          max: 0,
          callback: function(value, index, values) {
            return value + "%";
          }
        },
      },
    },
  };

  

  const selectedText = 'Det er opgjort, at Danmark som nation har reduceret \nCO₂-udledningen med 38 % fra 1990 til 2019.\nDet antages, at folkekirken har fulgt denne udvikling.\nPå det grundlag er folkekirken under halvvejs mod at\nnå et klimamål på 70 % CO₂-reduktion i 2030.';
  const selectedTextsm = 'Det er opgjort, at Danmark som nation har reduceret CO₂-udledningen med 38 % fra 1990 til 2019. Det antages, at folkekirken har fulgt denne udvikling. På det grundlag er folkekirken under halvvejs mod at nå et klimamål på 70 % CO₂-reduktion i 2030.';
  const id = 1;
  return (
    <div className="graph-section-with-info right-section mcgraph">
      <Row>
        <Col xs={10} className="mt-3">
          <p className="mt-1 mb-4">Figuren viser CO₂-udledningen fra energiforbruget, og hvor meget den skal reduceres for at nå et klimamål om 70 % reduktion i 2030. Folkekirken som samlet organisation ønsker at bidrage til samfundets grønne omstilling og dermed Folketingets mål om, at CO₂-emissionen i 2030 skal være 70 % lavere end i 1990.</p>
        </Col>
        <Col xs={2} className="prInfo">
          <InfoBox selectedText={isSmallScreen ? selectedTextsm : selectedText} id={id}/>
        </Col>

      </Row>
      <h4 className="title-spacing">CO₂-udledning ift. 70 %-reduktionsmål</h4>
      <div className= "d-flex flex-row">
        <p className="col-7 style3 mb-0">Ton CO₂</p>
        <p className="col-5 right style3 mb-0">Procentvis reduktion</p>
      </div>
        <Chart type="bar" className="min-graph-h"  data={data} options={options} height={isSmallScreen ? '900px' : '200px'}  width="700px" />

      
    </div>
  );
};

export default MixedChart;
