import React, { useEffect, useState } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import global from './global.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StackedBar2 = ({ selectedId }) => {

  const [StackedB2Data, setStackedB2Data] = useState([]);

  useEffect(() => {

    const fetchStackedB2Data = async () => {

      try {
        const response = await fetch(`${global.url}/Co2Emission/GetCo2Emissions`);

        const data = await response.json();

        setStackedB2Data(data);
      } 
      catch (error) {
        console.error('Error fetching data:', error);
      } 
    };
    
    const fetchStackedB2DataStift = async () => {

      try {
        const response = await fetch(`${global.url}/Co2Emission/GetCo2EmissionsByDioceseId/${selectedId}`);

        const data = await response.json();

        setStackedB2Data(data);
      } 
      catch (error) {
        console.error('Error fetching data:', error);
      } 
    };

    const fetchStackedB2DataProvsti = async () => {

      try {
        const response = await fetch(`${global.url}/Co2Emission/GetCo2EmissionsByDeaneryId/${selectedId}`);

        const data = await response.json();

        setStackedB2Data(data);
      } 
      catch (error) {
        console.error('Error fetching data:', error);
      } 
    };

    const fetchStackedB2DataMr = async () => {

      try {
        const response = await fetch(`${global.url}/Co2Emission/GetCo2EmissionsByCongregationId/${selectedId}`);

        const data = await response.json();

        setStackedB2Data(data);
      } 
      catch (error) {
        console.error('Error fetching data:', error);
      } 
    };

    if (!isNaN(selectedId) && selectedId > 0) {
      if (selectedId>  4300 && selectedId <= 4310){
        fetchStackedB2DataStift();
      }
      else if (selectedId > 4310 && selectedId < 4522) {
        fetchStackedB2DataProvsti();
      }
      else if (selectedId > 4521) {
        fetchStackedB2DataMr();
      }

    }
    else{
      if (
        (localStorage.getItem('StiftId') == '0' && localStorage.getItem('ProvstiId') == 'null' && localStorage.getItem('MrId') == 'null')|| 
        (localStorage.getItem('StiftId') == null && localStorage.getItem('ProvstiId') == null && localStorage.getItem('MrId') == null)
      ){
      fetchStackedB2Data();
      }
    }
  }, [selectedId]);

  const uniqueYears = [];
  StackedB2Data.map(emssn => {
      if (uniqueYears.indexOf(emssn.year) === -1) {
        uniqueYears.push(emssn.year)
      }
  });

  const filter = (category, arr, year, calculation) => arr.filter(emssn => emssn.consumptionType === category && emssn.year === year && emssn.extrapolation == calculation);

    const options = {
      maintainAspectRatio: false,
        plugins: {
          title: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                  var label = context.dataset.label || '';
                  if (context.parsed.y !== null) {
                      label += ' '+': ' +context.parsed.y.toLocaleString('da-DK') + ' Ton CO₂';
                  }
                  return label;
              }
            }
          } 
        },
        responsive: true,
        scales: {
          x: {
            grid: {
              drawOnChartArea: false,
            },
            stacked: true,
            barThickness: "flex",
          },
          y: {
            beginAtZero: true, 
            stacked: true,
          },
        },
    };

    const labels = uniqueYears;
    const data = {
        labels,
        datasets: [
          {
            label: 'Registreret Øvrige indkøb',
            data: [filter('Øvrige indkøb', StackedB2Data, 2019, 'Nej').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2), filter('Øvrige indkøb', StackedB2Data, 2022, 'Nej').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2)],
            backgroundColor: '#006699',
          },
          {
            label: 'Beregnet Øvrige indkøb',
            data: [filter('Øvrige indkøb', StackedB2Data, 2019, 'Ja').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2), filter('Øvrige indkøb', StackedB2Data, 2022, 'Ja').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2)],
            backgroundColor: '#80acce',
          },
          {
            label: 'Registreret Energiforbrug',
            data: [filter('Energiforbrug', StackedB2Data, 2019, 'Nej').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2), filter('Energiforbrug', StackedB2Data, 2022, 'Nej').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2)],
            backgroundColor: '#F89933',
          },
          {
            label: 'Beregnet Energiforbrug',
            data: [filter('Energiforbrug', StackedB2Data, 2019, 'Ja').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2), filter('Energiforbrug', StackedB2Data, 2022, 'Ja').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2)],
            backgroundColor: '#FBCC99',
          },
          {
            label: 'Registreret Transport',
            data: [filter('Transport', StackedB2Data, 2019, 'Nej').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2), filter('Transport', StackedB2Data, 2022, 'Nej').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2)],
            backgroundColor: '#CC3333',
          },
          {
            label: 'Beregnet Transport',
            data: [filter('Transport', StackedB2Data, 2019, 'Ja').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2), filter('Transport', StackedB2Data, 2022, 'Ja').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2)],
            backgroundColor: '#E68B8E',
          },
        ],
      };
    return(
        <div className="graph-section left-section" >
            <p className="mb-4">Udover energiforbruget er der også en stor CO₂-udledning fra indkøbte
            produkter og services, der også har krævet energi, brændstof etc.
            Figuren viser enhedens samlede CO₂-udledning fordelt på kategorierne:
            <i> Øvrige indkøb</i> (scope 3), energiforbrug (scope 1+2+3) og transport (scope 3).</p>
            <h4 className="title-spacing mb-3">Samlet CO₂-udledning for enheden</h4>
            <p className="style3 mb-0">Ton CO₂</p>

            <div className="stacked-pd">
              <Bar options={options} data={data} height={"350px"}/>   
            </div>
     
        </div>
    );

}

export default StackedBar2;

