import React from "react";
import { useNavigate, Outlet } from "react-router-dom";

const Vejledning = () => {
  const navigate = useNavigate();

  return (
    <div className="container-fluid px-sm-3">
      <div className="row">
        <h1 className="title">VEJLEDNING</h1>
        <h2 className="title title-spacing mb-3">CO₂</h2>
        <p className="vejl-text">CO₂e omfatter både CO₂ og alle andre gasser, der påvirker klimaet. Vi skriver dog CO₂ for at gøre teksten mere læsevenlig.</p>
        
        <h2 className="title title-spacing mb-3 mt-3">Scope 1, 2 og 3</h2>
        <p className="vejl-text">Udledning af CO₂ og andre drivhusgasser opdeles i scope 1, 2 og 3 for at tydeliggøre, hvor gasserne kommer fra. Denne 
        opdeling anvendes i hele verden.</p>
        
        <h3 className="title mb-1 mt-2">Scope 1</h3>
        <p className="vejl-text">Enhver forbrænding af benzin, gas, træ mv. udleder CO₂ til atmosfæren. Når det sker i den valgte enheds eget udstyr, hører 
        det til i Scope 1. Det er fx naturgas, der bruges i gaskedlen, eller benzin der bruges i græsslåmaskinen.</p>
        <h3 className="title mb-1 mt-2">Scope 2</h3>
        <p className="vejl-text">Når den valgte enhed bruger el og fjernvarme, opgøres det som Scope 2. Det skyldes at selve CO₂-udledningen er sket i et 
        elværk eller fjernvarmeværk, og ikke på den valgte enheds matrikel. </p>
        <h3 className="title mb-1 mt-2">Scope 3</h3>
        <p className="vejl-text">For at den valgte enhed kan fungere, er der brug for meget andet end energi (Scope 1 og 2). 
        Varer købes ind, bygninger renoveres, og måske hjælper eksterne virksomheder med at vedligeholde kirkegården. Det hele er noget, den valgte enhed 
        betaler for, og alle tingene medfører CO₂-udledninger. Alle indkøb – der ikke er energi – er samlet i Scope 3. I scope 3 kan køb af bygninger eller 
        renoveringer have et stort aftryk på klimaregnskabet, men det sker ikke hvert år. Husk derfor på alle de scope 3 udledninger, der sker hvert år.</p>
      
        <h2 className="title title-spacing mb-3 mt-3">Brændstof, opvarmning og elektricitet</h2>
        <p className="vejl-text">Opdeling i brændstof, varme og elektricitet viser, hvilke energiforbrug der udleder mest CO₂.</p>

        <h3 className="title mb-1 mt-2">Brændstof</h3>
        <p className="vejl-text">Brændstof omfatter CO₂ fra benzin, diesel og gas etc. til værktøj, maskiner og evt. køretøjer.</p>

        <h3 className="title mb-1 mt-2">Opvarming</h3>
        <p className="vejl-text">Opvarmning omfatter CO₂ fra produktion af varme, enten fjernvarme, naturgas eller fyringsolie.</p>

        <h3 className="title mb-1 mt-2">Elektricitet</h3>
        <p className="vejl-text">Elektricitet omfatter CO₂ fra produktion af elektricitet.</p>

        <h2 className="title title-spacing mb-3 mt-3">Transport, energi og indkøb</h2>
        <p className="vejl-text">Opdeling i transport, energi, og indkøb illustrerer, hvor man med størst effekt kan reducere sin udledning.</p>

        <h3 className="title mb-1 mt-2">Transport</h3>
        <p className="vejl-text">Transport omfatter CO₂ fra transport, der betales af folkekirken. Dette er f.eks. kirkebil, bus, taxa eller præsters
        kørsel i pastoratet.</p>

        <h3 className="title mb-1 mt-2">Energi</h3>
        <p className="vejl-text">Energi omfatter CO₂ fra produktion og forbrug af energi. Dette dækker over diesel, benzin, gas, olie, elektricitet, fjernvarme mm.</p>

        <h3 className="title mb-1 mt-2">Indkøb</h3>
        <p className="vejl-text">Indkøb omfatter CO₂ fra produktion og bearbejdning af indkøbte produkter og udførelse af services.</p>

        <h2 className="title title-spacing mb-3 mt-3">Beregnede data</h2>
        <p className="vejl-text">Beregnede data er energimængder og CO₂ -udledninger herfra - udregnet på baggrund af enhedens energiudgifter.</p>

        <h2 className="title title-spacing mb-3 mt-3">Grøn energi og fossile brændstoffer</h2>
        <p className="vejl-text">Energi fra sol, vind, hav eller biomateriale kaldes grøn energi. Energi fra ikke-vedvarende energikilder, såsom kul, olie, naturgas
         og LPG kaldes fossile brændsler.</p>

        <h2 className="title title-spacing mb-3 mt-3">Biofaktor</h2>
        <p className="vejl-text">Biofaktor er et udtryk for den biologiske aktivitet, som et område levner plads til – det vil sige, hvor nemt biologisk diversitet 
        trives i området. Biofaktoren for et område udregnes ved at finde forholdet mellem biologisk volumen og områdets samlede friareal.</p>
      </div>


    </div>
  );
};

export default Vejledning;
