import React, { useEffect, useState } from "react"
import Table from 'react-bootstrap/Table';
import $ from 'jquery';
import SamletBiofaktorIkon from './Samlet-biofaktor-ikon.png';
import global from './global.js';

const DataTableBio = ({ selectedId }) => {

    const [biotable, setBioTableData] = useState([])

    useEffect(() => {

    
        const fetchBioTableDataKr = async () => {
    
          try {
            const response = await fetch(`${global.url}/Biodiversity/GetBiodiversityDataByCemeteryId/${selectedId}`);
    
            const data = await response.json();
    
            setBioTableData(data);
          } 
          catch (error) {
            console.error('Error fetching data:', error);
          } 
        };

        fetchBioTableDataKr();
      }, [selectedId]);

    const filter = (category, arr) => arr.filter(table => table.name === category);

    $(document).ready(function() {
        $('tr:odd td').addClass('green-row');
        $('tr td').addClass('small-table-p');
        $('tr th').addClass('small-table-h');
      });


    return (
        <div className="table-section mt-4">
            <p className="style1 mb-3">Arealer oplyst i spørgeskema</p>
            <Table>
                <thead>
                    <tr className="table-head">
                        <th>Basisarealer opgjort <i>i m<sup>2</sup></i></th>
                        <th className="center">Kategoriens biofaktor</th>
                        <th className="right">m<sup>2</sup></th>
                    </tr>
                </thead>
                <tbody className="table-border transparent">

                    <tr>
                        <td >
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                            Belagt areal, asfalt og fliser
                        </td>
                        <td className=" center">0,0</td>
                        {filter('Belagt areal (fx asfalt og fliser)',biotable).map(table =>(
                            <td className=" right">{table.emissionInTonne != null ? table.emissionInTonne.toLocaleString('da-DK') : '-'}</td>
                        ))}
                    </tr>

                    <tr>
                        <td >
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                            Kirkegårdssten, græsarmering, grusarealer
                        </td>
                        <td className=" center">0,1</td>
                        {filter('Kirkegårdssten, græsarmering, grusarealer',biotable).map(table =>(
                            <td className=" right">{table.emissionInTonne != null ? table.emissionInTonne.toLocaleString('da-DK') : '-'}</td>
                        ))}
                    </tr>

                    <tr>
                        <td >
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                            Klippet græs, ugentligt, efter behov
                        </td>
                        <td className="  center">0,2</td>
                        {filter('Klippet græs (ugentligt, efter behov)',biotable).map(table =>(
                            <td className=" right">{table.emissionInTonne != null ? table.emissionInTonne.toLocaleString('da-DK') : '-'}</td>
                        ))}
                    </tr>

                    <tr>
                        <td >
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                            Naturgræs, lav beplantning
                        </td>
                        <td className=" center">0,4</td>
                        {filter('Naturgræs, lav beplantning',biotable).map(table =>(
                            <td className=" right">{table.emissionInTonne != null ? table.emissionInTonne.toLocaleString('da-DK') : '-'}</td>
                        ))}
                    </tr>

                    <tr>
                        <td >
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                            Krat og buske, under to meter høje
                        </td>
                        <td className="center">0,6</td>
                        {filter('Krat og buske under to meter høje',biotable).map(table =>(
                            <td className=" right">{table.emissionInTonne != null ? table.emissionInTonne.toLocaleString('da-DK') : '-'}</td>
                        ))}
                    </tr>                
                    
                    <tr>
                        <td >
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                            Krat og buske over to meter høje
                        </td>
                        <td className=" center">1,0</td>
                        {filter('Krat og buske over to meter høje',biotable).map(table =>(
                            <td className=" right">{table.emissionInTonne != null ? table.emissionInTonne.toLocaleString('da-DK') : '-'}</td>
                        ))}
                    </tr>

                    <tr>
                        <td >
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                            Træ- og skovplantninger
                        </td>
                        <td className="  center">2,0</td>
                        {filter('Træ- og skovplantninger',biotable).map(table =>(
                            <td className=" right">{table.emissionInTonne != null ? table.emissionInTonne.toLocaleString('da-DK') : '-'}</td>
                        ))}
                    </tr>

                    <tr>
                        <td >
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                            Vandareal, permanent
                        </td>
                        <td className=" center">1,0</td>
                        {filter('Vandareal, permanent',biotable).map(table =>(
                            <td className=" right">{table.emissionInTonne != null ? table.emissionInTonne.toLocaleString('da-DK') : '-'}</td>
                        ))}
                    </tr>

                    <tr >
                        <td >
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                        </td>
                        <td className="  center"></td>
                        <td className=" center"></td>
                    </tr>

                    <tr className="table-default total-row bold-text">
                        <td >
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                            Grund-biofaktor
                        </td>
                        <td></td>
                        {filter('Grundbiofaktor',biotable).map(table =>(
                            <td className=" right">{table.emissionInTonne != null ? table.emissionInTonne.toLocaleString('da-DK') : '-'}</td>
                        ))}
                    </tr>

                    <tr>
                        <td >
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                        </td>
                        <td className=" center"></td>
                        <td className=" center"></td>
                    </tr>

                    <tr>
                        <td className="bold-text">
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                            Tillægsarealer opgjort <i>i antal</i>
                        </td>
                        <td className="bold-text center">Kategoriens biofaktor</td>
                        <td className="bold-text right">Antal</td>
                    </tr>

                    <tr>
                        <td >
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                            Små træer, stammeomkreds 10-16 cm
                        </td>
                        <td className=" center">2,0</td>
                        {filter('Små træer, stammeomkreds10-16 cm',biotable).map(table =>(
                            <td className=" right">{table.emissionInTonne != null ? table.emissionInTonne.toLocaleString('da-DK') : '-'}</td>
                        ))}
                    </tr>
                    
                    <tr>
                        <td >
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                            Mellem træer, stammeomkreds 17-25 cm
                        </td>
                        <td className="center">2,0</td>
                        {filter('Mellem træer, stammeomkreds17-25 cm',biotable).map(table =>(
                            <td className=" right">{table.emissionInTonne != null ? table.emissionInTonne.toLocaleString('da-DK') : '-'}</td>
                        ))}
                    </tr>

                    <tr>
                        <td >
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                            Store træer, stammeomkreds 26-35 cm
                        </td>
                        <td className=" center">2,0</td>
                        {filter('Store træer, stammeomkreds26-35 cm',biotable).map(table =>(
                            <td className=" right">{table.emissionInTonne != null ? table.emissionInTonne.toLocaleString('da-DK') : '-'}</td>
                        ))}
                    </tr>

                    <tr>
                        <td >
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                            XL træer, stammeomkreds 36-60 cm
                        </td>
                        <td className="center">2,0</td>
                        {filter('XL træer, stammeomkreds36-60 cm',biotable).map(table =>(
                            <td className=" right">{table.emissionInTonne != null ? table.emissionInTonne.toLocaleString('da-DK') : '-'}</td>
                        ))}
                    </tr>

                    <tr>
                        <td >
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                        </td>
                        <td className=" center"></td>
                        <td className=" center"></td>
                    </tr>

                    <tr>
                        <td className="bold-text">
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                            Tillægsarealer opgjort <i>i m<sup>2</sup></i>
                        </td>
                        <td className="bold-text center">Kategoriens biofaktor</td>
                        <td className="bold-text right">m<sup>2</sup></td>
                    </tr>

                    <tr>
                        <td >
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                            Enkeltstående, meget store træer
                        </td>
                        <td className=" center">2,0</td>
                        {filter('Enkeltstående, meget store træer',biotable).map(table =>(
                            <td className=" right">{table.emissionInTonne != null ? table.emissionInTonne.toLocaleString('da-DK') : '-'}</td>
                        ))}
                    </tr>

                    <tr>
                        <td >
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                            Grønne tage
                        </td>
                        <td className="center">0,5</td>
                        {filter('Grønne tage',biotable).map(table =>(
                            <td className=" right">{table.emissionInTonne != null ? table.emissionInTonne.toLocaleString('da-DK') : '-'}</td>
                        ))}
                    </tr>

                    
                    <tr>
                        <td >
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                            Facadebeplantning
                        </td>
                        <td className=" center">0,3</td>
                        {filter('Facadebeplantning',biotable).map(table =>(
                            <td className=" right">{table.emissionInTonne != null ? table.emissionInTonne.toLocaleString('da-DK') : '-'}</td>
                        ))}
                    </tr>

                    <tr>
                        <td >
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                            Skybruds- og LAR-areal med beplantning
                        </td>
                        <td className="center">0,5</td>
                        {filter('Skybruds- og LAR-areal med beplantning',biotable).map(table =>(
                            <td className="right ">{table.emissionInTonne != null ? table.emissionInTonne.toLocaleString('da-DK') : '-'}</td>
                        ))}
                    </tr>

                    <tr>
                        <td >
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                        </td>
                        <td className=" center"></td>
                        <td className=" center"></td>
                    </tr>

                    <tr className="table-default total-row bold-text">
                        <td>
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                            Biofaktor-tillæg
                        </td>
                        <td></td>
                        {filter('Biofaktor-tillæg',biotable).map(table =>(
                            <td className="right ">{table.emissionInTonne != null ? table.emissionInTonne.toLocaleString('da-DK') : '-'}</td>
                        ))}
                    </tr>
                    
                    <tr>
                        <td className=" ">
                            <span className="transparent d-md-inline-flex d-none"> ""</span>
                        </td>
                        <td className=" center"></td>
                        <td className=" center"></td>
                    </tr>
                    <tr className="table-default-double total-row bold-text">
                        <td>
                            <h3 className="title-spacing mb-0 mt-md-4">Samlet biofaktor</h3>
                        </td>
                        <td></td>
                        {filter('Samlet biofaktor',biotable).map(table =>(
                            <td className="title-spacing center">
                                <img className=" samlet-bio-icon" src={SamletBiofaktorIkon} />
                                <h3 className="title-spacing center mb-0 mt-md-4 bio-col-val mtBio">{table.emissionInTonne != null ? table.emissionInTonne.toLocaleString('da-DK') : '-'}</h3>
                            </td>
                            
                        ))}
                    </tr>
                </tbody>
            </Table>
        </div>
    );
};

export default DataTableBio;
