import React, { useState, useEffect } from 'react';
import $ from 'jquery'
import Table from 'react-bootstrap/Table';
import global from './global.js';

const DataTableKlima = ({ selectedId }) => {

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
    const [klimatable, setKlimaTable] = useState([])

    useEffect(() => {
      const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 768);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    useEffect(() => {

        const fetchKlimaTableData = async () => {
    
          try {
            const response = await fetch(`${global.url}/Co2Emission/GetCo2EmissionsForOtherPurchases`);
    
            const data = await response.json();
    
            setKlimaTable(data);
          } 
          catch (error) {
            console.error('Error fetching data:', error);
          } 
        };
        
        const fetchKlimaTableDataStift = async () => {
    
          try {
            const response = await fetch(`${global.url}/Co2Emission/GetCo2EmissionsForOtherPurchasesByDioceseId/${selectedId}`);
    
            const data = await response.json();
    
            setKlimaTable(data);
          } 
          catch (error) {
            console.error('Error fetching data:', error);
          } 
        };
    
        const fetchKlimaTableDataProvsti = async () => {
    
          try {
            const response = await fetch(`${global.url}/Co2Emission/GetCo2EmissionsForOtherPurchasesByDeaneryId/${selectedId}`);
    
            const data = await response.json();
    
            setKlimaTable(data);
          } 
          catch (error) {
            console.error('Error fetching data:', error);
          } 
        };
    
        const fetchKlimaTableDataMr = async () => {
    
          try {
            const response = await fetch(`${global.url}/Co2Emission/GetCo2EmissionsForOtherPurchasesByCongregationId/${selectedId}`);
    
            const data = await response.json();
    
            setKlimaTable(data);
          } 
          catch (error) {
            console.error('Error fetching data:', error);
          } 
        };

        if (!isNaN(selectedId) && selectedId > 0) {
          if (selectedId>4300 && selectedId <= 4310){
            fetchKlimaTableDataStift();
          }
          else if (selectedId > 4310 && selectedId < 4522) {
            fetchKlimaTableDataProvsti();
          }
          else if (selectedId > 4521) {
            fetchKlimaTableDataMr();
          }
    
        }
        else{
          if (
            (localStorage.getItem('StiftId') == '0' && localStorage.getItem('ProvstiId') == 'null' && localStorage.getItem('MrId') == 'null')|| 
            (localStorage.getItem('StiftId') == null && localStorage.getItem('ProvstiId') == null && localStorage.getItem('MrId') == null)
          ){
          fetchKlimaTableData();
          }
        }
      }, [selectedId]);

    $(document).ready(function() {
      $('tr:odd td').addClass('green-row');
      $('tr td').addClass('small-table-p');
      $('tr th').addClass('small-table-h');
    });

    function round(value, precision) {
      var multiplier = Math.pow(10, precision || 0);
      return Math.round(value * multiplier) / multiplier;
    }

    function roundToString(value, precision) {
      return round(value, precision).toLocaleString('da-DK', { minimumFractionDigits: precision });
    }
  
    return (
        <div className="table-section mt-4">
            <p className="mb-4">Tabellen viser, hvordan CO₂-udledningen fra kategorien <i>Øvrige indkøb</i> fordeler sig på kontoposter fra enhedens regnskab.</p>
            <h4 className="title-spacing mb-3">CO₂-udledning fra <i>Øvrige indkøb</i></h4>
            <Table>
                <thead>
                    <tr className="table-default" key='-1'>
                        <th key='-2'>
                            Kategori
                        </th>
                        <th className="right" key='-3'>
                            {isSmallScreen ? '2019 (ton CO₂)' : 'Udledning i 2019 (ton CO₂)'}
                        </th>
                        <th className="right" key='-4'>
                            {isSmallScreen ? '2022 (ton CO₂)' : 'Udledning i 2022 (ton CO₂)'}
                        </th>
                    </tr>
                </thead>
                <tbody className="table-border transparent">
                    
                        {klimatable.map(row => (
                            <tr>
                                <td>{row.name}</td>
                                <td className="right">{row.co2EmissionPerYear[0] && row.co2EmissionPerYear[0].year === 2019 ? roundToString(row.co2EmissionPerYear[0].emissionInTonne,1) : "-"}</td>
                                {row.co2EmissionPerYear[1] ?
                                  <td className="right">{row.co2EmissionPerYear[1] && row.co2EmissionPerYear[1].year === 2022 ? roundToString(row.co2EmissionPerYear[1].emissionInTonne,1) : "-"}</td>                                                          
                                 : 
                                 <td className="right">{row.co2EmissionPerYear[0] && row.co2EmissionPerYear[0].year === 2022 ? roundToString(row.co2EmissionPerYear[0].emissionInTonne,1) : "-"}</td>                                                          
                                 }                         
                            </tr>
                        ))}
                    
                </tbody>
            </Table>
        </div>
    );
};

export default DataTableKlima;

