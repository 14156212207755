import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Asset from './Asset.png';

const InfoBox = ({ selectedText, id }) => {
    const [anchorEl, setAnchorEl] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverWidth = id === 1 ? '75%' : 'auto';


    return (
        <div className="d-flex justify-content-end">
            <Button
                sx={{ textTransform: 'none', color: 'rgb(128, 128, 128)' }}
                onClick={handleClick}>
                Info
                 <img className='question-icn' src={Asset} alt='question-icn'/>
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                sx={{
                    '& .MuiPopover-paper': {
                      backgroundColor: 'rgb(254 241 208)',
                    },

                  }}
            >
                <Typography style={{ whiteSpace: 'pre-wrap'}} sx={{ p: 3 }}>{selectedText}</Typography>
            </Popover>
        </div>
    );
}

export default InfoBox;
