import * as React from 'react';
import { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Slider  from 'rc-slider';
import BiofaktorIkon from './Biofaktor-ikon.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'rc-slider/assets/index.css';
import global from './global.js';
import Slider2 from '@mui/material/Slider';


function valuetext(value) {
    return `${value}°C`;
}

const BioSlider = ({ selectedId }) => {
    const [biotable, setBioTableData] = useState([])

    const [value, setValue] = React.useState([0.25, 0.65]);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    useEffect(() => {
    
        const fetchBioTableDataKr = async () => {
    
          try {
            const response = await fetch(`${global.url}/Biodiversity/GetBiodiversityDataByCemeteryId/${selectedId}`);
    
            const data = await response.json();
    
            setBioTableData(data);
          } 
          catch (error) {
            console.error('Error fetching data:', error);
          } 
        };
    
        fetchBioTableDataKr();

      }, [selectedId]);

    const filter = (category, arr) => arr.filter(table => table.name === category);
    
    const samletBiofaktor = filter('Samlet biofaktor',biotable).map(table =>(table.emissionInTonne))

  var samlet1 = samletBiofaktor[0]

  const [samlet, setSamlet] = useState();
  const [sliderValue, setSliderValue] = useState();


  const handleSamletChange = () => {
    setSamlet(samlet1)
    setSliderValue(samlet1);
  };

  useEffect(() => {
    handleSamletChange();
  }, [samlet1]);

    const mark = typeof sliderValue !== 'undefined' ? { [sliderValue]: sliderValue.toLocaleString('da-DK') } : {};
    return (
        <div className="graph-section bio-slider-div right-section">
            <Box >
              <Row>
                <Col xs={12}>
                  <p className="mb-1">Figuren viser biofaktoren for den valgte enhed, altså hvor meget biodiversitet et område understøtter.</p>
                  <p className="mb-4">De fleste kirkegårde har en biofaktor mellem <span className="dot-sm"></span> 0,25 og <span className="dot-sm"></span> 0,65.</p>
                </Col>

                <Col xs={12}>
                  <h3 className="title-spacing" style={{display:'inline-block', width:'50%'}}>Belagt areal</h3>
                  <h3 className="right title-spacing" style={{display:'inline-block', width:'50%'}}>Træ- og skovplantning</h3>
                </Col>
                <Col xs={12}>
                  <h3 style={{display:'inline-block', width:'50%'}}>0</h3>
                  <h3 className="right" style={{display:'inline-block', width:'50%'}}>2</h3>
                </Col>
                <Col xs={12}>
                <Slider2
                    getAriaLabel={() => 'Temperature range'}
                    value={value}
                    max = {2}
                    min = {0}
                    disabled
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                  />
                </Col>
                <Col>
                  <div className="square mt-1">
                    <Slider
                      value={sliderValue}
                      marks={mark
                      }
                      trackStyle={{ backgroundColor: "transparent" }}
                      min={0}
                      max={2}
                      step={0.01}

                      handleStyle={{
                        height: 240,
                        width: 240,
                      }}
                    
                    />
                  </div>
                </Col>
              </Row>
            </Box>
            
        </div>
    );
}

export default BioSlider;
