import React from "react";
import { createRoot } from "react-dom/client";
import Main from "./Main";
import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './global.js';

const root = createRoot(document.getElementById("root"));

root.render(<Main />);
