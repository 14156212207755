import React, { useEffect, useState } from "react"
import { Outlet } from "react-router-dom";
import $ from 'jquery'
import DropdownMenu from './DropdownMenu.js'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import DataTableBio from './DataTableBio.js'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Slider from './Slider.js';
import Provice_logo from './Provice-logo_lille@2x.png'
import global from './global.js';

ChartJS.register(ArcElement, Tooltip, Legend);


const Biodiversitet = ({ selectedId, onChange }) => { 

  $(document).ready(function() {
    var windowHeight = $(window).height();
    $('.bioh').css('height', windowHeight);

  });

  const [cemetries, setCemetriesData] = useState([])

  const fetchCemetriesData = () => {
    fetch(`${global.url}/Organization/GetAllCemeteries`)
      .then (response => {
          return response.json()
      })
      .then(data => {
        setCemetriesData(data)
      })
  }

  useEffect(() => {
    fetchCemetriesData()
  }, [])

  const cemetries_avilable = cemetries.map(cemetry => cemetry.id);

  return (
    <div className="container-fluid bio px-sm-3">
      <div className="row">
        <h1 className="title">BIODIVERSITET</h1>
      </div>
      <div className="d-md-flex mb-3">
        <div className="col-12 col-md-6">          
          <h2 className="title title-spacing">Vælg enhed</h2>
        </div>
        <div className="col-12 col-md-6 ms-auto right">
          <p className="title sub-title mb-0">Vælg den enhed du vil se biodiversitetsdata for</p>
          <p className="sub-title">(Stift / Provsti / Menighedsråd – kirkegård)</p>
        </div>
    </div>

    <DropdownMenu selectedIdPassed={selectedId} onChange={onChange} />
    
    <Row className="biodiv-w">


    <Col xs={12}>

      {selectedId>=1 && selectedId<=136 ? (
        <div>
          <Col xs={12}><Slider selectedId={selectedId}/></Col>
          <Col><DataTableBio selectedId={selectedId}/></Col>

        </div>
      ) : (
        <div>

          <Col className="" xs={12}><h2 className="bioh title title-spacing"></h2></Col>
        </div>
      )}
    </Col>
    
    <Col className="mt-5" xs={12}>
      <div className="d-flex flex-row justify-content-end">
        <p className="mb-0">Udarbejdet af</p>
        <img className="ProviceLogo" src={Provice_logo} />
      </div>
    </Col>
    <Outlet />
    </Row>
  </div>
);
};

export default Biodiversitet;
