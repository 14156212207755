import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, NavLink, Route, Routes } from "react-router-dom";
import Klimaregnskab from "./Klimaregnskab";
import Biodiversitet from "./Biodiversitet";
import Vejledning from "./Vejledning";
import NotFound from "./NotFound";
import Klimaregnskab_ikon from './Klimaregnskabs-ikon.png';
import Biodiversitet_ikon from './Biodiversitet.png';
import Vejledning_ikon from './Vejledning.png';
import LogoFGO from './LogoFGO.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import Footer from './Footer.js'


const Main = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleDropdownChange = (id) => {
    setSelectedId(id);
  };
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClick = () => {
    setIsNavExpanded(!isNavExpanded);
  }

  return (
    <Router>
      <div>
        <nav className="header navbar" id="header">
          <div className="container-fluid">
            <a className="navbar-brand"><img src={LogoFGO} className="logofgo" alt="Folkekirkens Icon" /></a>
            <h4 className="title d-md-flex d-none justify-content-end mb-lg-0 right">OPGØRELSE AF KLIMAREGNSKAB OG BIODIVERSITET</h4>
            <button className="menu-icon" id="menu-icon" onClick={ () => {setIsNavExpanded(!isNavExpanded);}}>
              <FontAwesomeIcon className="bar-icon" icon={faBars} />
            </button>
          </div>
        </nav>         
        <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu container-fluid"}>
          <div id="navigator">
            <ul className="navigator d-md-flex flex-column" >
              <li className="col-12 col-md-1" >
                <NavLink id="klimapage"  to="/" className="navlink-item" onClick={isSmallScreen ? handleClick : undefined}>
                  <img className="mb-2" src={Klimaregnskab_ikon} alt="Klimaregnskab Icon" />
                  <span className="menu-option">Klimaregnskab</span>
                </NavLink>
              </li>
              <li className="col-12 col-md-1">
                <NavLink id="biopage" to="/biodiversitet" className="navlink-item" onClick={isSmallScreen ? handleClick : undefined}>
                  <img className="mb-2" src={Biodiversitet_ikon} alt="Biodiversitet Icon" />
                  <span className="menu-option">Biodiversitet</span>
                </NavLink>
              </li>
              <li className="col-12 col-md-1">
                <NavLink id="vejpage" to="/vejledning" className="navlink-item" onClick={isSmallScreen ? handleClick : undefined}>
                  <img className="mb-2" src={Vejledning_ikon} alt="Vejledning Icon" />
                  <span className="menu-option">Vejledning</span>
                </NavLink>
              </li>
            </ul>

          </div>
          <div className={isNavExpanded ? "content add" : "content"} >
            <div className='side-page'>
              <Routes>
                <Route exact path="/" element={<Klimaregnskab selectedId={selectedId} onChange={handleDropdownChange}/>} />
                <Route path="/biodiversitet" element={<Biodiversitet selectedId={selectedId} onChange={handleDropdownChange} />} />
                <Route path="/vejledning" element={<Vejledning />} />
                <Route path='*' element={<NotFound />}/>
              </Routes>
            </div>
            <Footer/>

          </div>

        </div>
      </div>
    </Router>
    
  );
};

export default Main;
