import React from "react"
import { Outlet } from "react-router-dom";
import PieCharts from './PieCharts.js';
import DropdownMenu from './DropdownMenu.js';
import DataTableKlima from './DatatableKlima.js';
import MixedChart from './MixedChart.js';
import StackedBar from './StackedBar.js';
import StackedBar2 from './StackedBar2.js';
import EnergyComsumptionPerEnergyTypeStackedBar from './EnergyComsumptionPerEnergyTypeStackedBar.js';
import EnergiforbrugBar from './EnergiforbrugBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Provice_logo from './Provice-logo_lille@2x.png'

const Klimaregnskab = ({ selectedId, onChange }) => { 

  return (
    <div className="container-fluid px-sm-3">
      <div className="row">
        <h1 className="title">KLIMAREGNSKAB</h1>
      </div>
      <div className="d-md-flex mb-3">
        <div className="col-12 col-md-6">          
          <h2 className="title title-spacing">Vælg enhed</h2>
        </div>
        <div className="col-12 col-md-6 ms-auto right">
          <p className="title sub-title mb-0">Vælg den enhed du vil se klimadata for.</p>
          <p className="sub-title">(Stift / Provsti / Menighedsråd)</p>
        </div>
      </div>
      <DropdownMenu selectedIdPassed={selectedId} onChange={onChange} />
      <h2 className="title title-spacing">Hvor stor er enhedens CO₂-udledning i dag, og hvad er målet?</h2>
      <Row>
        <Col xs={12} >
          <MixedChart selectedId={selectedId}/>
        </Col>
        <h2 className="title title-spacing">Hvor kommer CO₂-udledningen fra?</h2>

        <Col lg={6} xs={12}>
          <StackedBar selectedId={selectedId}/> 
        </Col>

        <Col lg={6} xs={12}>
          <PieCharts selectedId={selectedId}/>
        </Col>
        <h2 className="title title-spacing">Hvor meget energi bruges af hver energitype?</h2>

        <Col lg={6} xs={12}> 
          <EnergyComsumptionPerEnergyTypeStackedBar selectedId={selectedId}/>
        </Col>        

        <Col xs={12}>
          <EnergiforbrugBar selectedId={selectedId}/>
        </Col>

        <h2 className="title title-spacing">Hvor stor er enhedens samlede CO₂-udledning?</h2>

        <Col lg={6} xs={12}> 
          <StackedBar2 selectedId={selectedId}/>
        </Col>

        <Col xs={12}>
          <DataTableKlima selectedId={selectedId}/>
        </Col>
        <Col className="mt-5" xs={12}>
          <div className="d-flex flex-row justify-content-end">
            <p className="mb-0">Udarbejdet af</p>
            <img className="ProviceLogo" src={Provice_logo} />
          </div>
        </Col>
        <Outlet />

      </Row>

    </div>
  
  );
};

export default Klimaregnskab;

