import React, { useEffect, useState } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InfoBox from './InfoBox.js';
import global from './global.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StackedBar = ({ selectedId }) => {

  const [StackedBData, setStackedBData] = useState([]);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {

    const fetchStackedBData = async () => {

      try {
        const response = await fetch(`${global.url}/Co2Emission/GetCo2EmissionsPerConsumptionType`);

        const data = await response.json();

        setStackedBData(data);
      } 
      catch (error) {
        console.error('Error fetching data:', error);
      } 
    };
    
    const fetchStackedBDataStift = async () => {

      try {
        const response = await fetch(`${global.url}/Co2Emission/GetCo2EmissionsPerConsumptionTypeByDioceseId/${selectedId}`);

        const data = await response.json();

        setStackedBData(data);
      } 
      catch (error) {
        console.error('Error fetching data:', error);
      } 
    };

    const fetchStackedBDataProvsti = async () => {

      try {
        const response = await fetch(`${global.url}/Co2Emission/GetCo2EmissionsPerConsumptionTypeByDeaneryId/${selectedId}`);

        const data = await response.json();

        setStackedBData(data);
      } 
      catch (error) {
        console.error('Error fetching data:', error);
      } 
    };

    const fetchStackedBDataMr = async () => {

      try {
        const response = await fetch(`${global.url}/Co2Emission/GetCo2EmissionsPerConsumptionTypeByCongregationId/${selectedId}`);

        const data = await response.json();

        setStackedBData(data);
      } 
      catch (error) {
        console.error('Error fetching data:', error);
      } 
    };

    if (!isNaN(selectedId) && selectedId > 0) {
      if (selectedId>4300 && selectedId <= 4310){
        fetchStackedBDataStift();
      }
      else if (selectedId > 4310 && selectedId < 4522) {
        fetchStackedBDataProvsti();
      }
      else if (selectedId > 4521) {
        fetchStackedBDataMr();
      }

    }
    else{
      if (
        (localStorage.getItem('StiftId') == '0' && localStorage.getItem('ProvstiId') == 'null' && localStorage.getItem('MrId') == 'null')|| 
        (localStorage.getItem('StiftId') == null && localStorage.getItem('ProvstiId') == null && localStorage.getItem('MrId') == null)
      ){
      
        fetchStackedBData();
      }
    }
  }, [selectedId]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  const uniqueYears = [];
  StackedBData.map(emssn => {
      if (uniqueYears.indexOf(emssn.year) === -1) {
        uniqueYears.push(emssn.year)
      }
  });

  const filter = (category, arr,year, calculation) => arr.filter(emssn => emssn.consumptionType === category && emssn.year === year && emssn.extrapolation === calculation);

    const options = {
      maintainAspectRatio: false,
        plugins: {
          title: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                  var label = context.dataset.label || '';
                  if (context.parsed.y !== null) {
                      label += ' '+': ' +context.parsed.y.toLocaleString('da-DK') + ' Ton CO₂';
                  }
                  return label;
              }
            }
          } 
        },
        responsive: true,
        scales: {
          x: {
            grid: {
              drawOnChartArea: false,
            },
            stacked: true,
            barThickness: "flex",
          },
          y: {
            beginAtZero: true, 
            stacked: true,
          },
        },
    };

    const labels = uniqueYears;

    const data = {
        labels,
        datasets: [
          {
            label: 'Registreret Elektricitet',
            data: [filter('Elektricitet', StackedBData, 2019, 'Nej').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2), filter('Elektricitet', StackedBData, 2022, 'Nej').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2)],
            backgroundColor: '#006699',
          },
          {
            label: 'Beregnet Elektricitet',
            data: [filter('Elektricitet', StackedBData, 2019, 'Ja').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2), filter('Elektricitet', StackedBData, 2022, 'Ja').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2)],
            backgroundColor: '#80acce',
          },
          {
            label: 'Registreret Opvarmning',
            data: [filter('Opvarmning', StackedBData, 2019, 'Nej').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2), filter('Opvarmning', StackedBData, 2022, 'Nej').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2)],
            backgroundColor: '#F89933',
          },
          {
            label: 'Beregnet Opvarmning',
            data: [filter('Opvarmning', StackedBData, 2019, 'Ja').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2), filter('Opvarmning', StackedBData, 2022, 'Ja').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2)],
            backgroundColor: '#FBCC99',
          },
          {
            label: 'Registreret Brændstof',
            data: [filter('Brændstof', StackedBData, 2019, 'Nej').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2), filter('Brændstof', StackedBData, 2022, 'Nej').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2)],
            backgroundColor: '#CC3333',
          },
          {
            label: 'Beregnet Brændstof',
            data: [filter('Brændstof', StackedBData, 2019, 'Ja').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2), filter('Brændstof', StackedBData, 2022, 'Ja').map(emssn => emssn.emissionInTonne).reduce((accumulator, current) => accumulator + current, 0).toFixed(2)],
            backgroundColor: '#E68B8E',
          },
        ],
      };

    const selectedText = 'Beregnede data er baseret på udgifter fra den \nvalgte enheds årsregnskab.';
    const selectedTextsm = 'Beregnede data er baseret på udgifter fra den valgte enheds årsregnskab.';
    const id = 0;

    return(
        <div className="graph-section-with-info left-section" >
          <Row>
            <Col xs={8} className="mt-3">
              <p className="mt-1 mb-4">Figuren viser CO₂-udledningen fra den valgte enheds energiforbrug.</p>
            </Col>
            <Col xs={4} className="prInfo">
              <InfoBox selectedText={isSmallScreen ? selectedTextsm : selectedText} id={id}/>
            </Col>

          </Row>
            <h4 className="title-spacing mb-3">Udledning pr. energitype</h4>
            <p className="style3 mb-0">Ton CO₂</p>

            <div className="stacked-pd">

              <Bar options={options} data={data} height={"350px"}/>   
            </div>
     
        </div>
    );

}

export default StackedBar;

